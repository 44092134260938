const Services = () => {
  return (
    <>
      <section
        className="service-area bg-relative pd-top-100"
        id="service_section"
      >
        <img
          className="position-bottom-left top_image_bounce"
          src="/assets/img/icon/4.png"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">CREATIVE SERVICES</h6>
            <h2 className="title">
              Your Partner In <span>Digital</span> Success
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="/assets/img/service-icon/1.png" alt="img" />
                </div>
                <div className="details">
                  <h5>CUSTOMER MANAGEMENT SYSTEM DEVELOPMENT</h5>
                  <p>
                    {" "}
                    Mining should be fair & easy! We do not allow asics on our
                    Network. The development team is wholy commited to with
                    Keeping it that way.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="/assets/img/service-icon/2.png" alt="img" />
                </div>
                <div className="details">
                  <h5>WEB APPLICATION DEVELOPMENT</h5>
                  <p>
                    {" "}
                    Our web application development helps to implement software
                    that can be accessed from a platform-independent web browser
                    as your single interface.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="/assets/img/icon/2.png" alt="img" />
                </div>
                <div className="details">
                  <h5>MOBILE APPLICATION DEVELOPMENT</h5>
                  <p>
                    {" "}
                    We are developing creative and customized mobile application
                    with the help of research and understanding that stems from
                    experience and expertise.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="/assets/img/service-icon/1.png" alt="img" />
                </div>
                <div className="details">
                  <h5>TRENDING APPS</h5>
                  <p>
                    Applications which are trending these days in the market &
                    fashionable these days and people goes crazy over them.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="/assets/img/service-icon/2.png" alt="img" />
                </div>
                <div className="details">
                  <h5>ECOMMERCE</h5>
                  <p>
                    We build your online store using a flexible, modular
                    platform that allow.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-service-inner text-center">
                <div className="thumb">
                  <img src="/assets/img/icon/2.png" alt="img" />
                </div>
                <div className="details">
                  <h5>BRANDING</h5>
                  <p>
                    A solid brand strategy, logo and guidelines help you to get
                    You recognized.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="about-area bg-gradient-gray pd-top-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 wow  fadeInLeft animated"
              data-wow-duration="1.5s"
              data-wow-delay="0.3s"
              style={{
                visibility: "visible",
                animationDuration: "1.5s",
                animationDelay: "0.3s",
                animationName: "fadeInLeft",
              }}
            >
              <div className="about-thumb-inner mb-4 mb-lg-0">
                <img
                  className="main-img top_image_bounce"
                  src="/assets/img/about/11.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow  fadeInRight animated"
              data-wow-duration="1.5s"
              data-wow-delay="0.3s"
              style={{
                visibility: "visible",
                animationDuration: "1.5s",
                animationDelay: "0.3s",
                animationName: "fadeInRight",
              }}
            >
              <div className="section-title mb-0">
                <h6 className="sub-title">Modern architecture support</h6>
                <h2 className="title">
                  Let’s start a <span>project</span> together
                </h2>
                <p className="content mb-4">
                  We believe that something truly amazing can come from
                  combining our experience with your vision.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <svg
                          className="svg-inline--fa fa-check-circle fa-w-16"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="check-circle"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                          ></path>
                        </svg>
                        Backend Development
                      </li>
                      <li>
                        <svg
                          className="svg-inline--fa fa-check-circle fa-w-16"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="check-circle"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                          ></path>
                        </svg>
                        Frontend Development
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <svg
                          className="svg-inline--fa fa-check-circle fa-w-16"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="check-circle"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                          ></path>
                        </svg>
                        Mobile App Development
                      </li>
                      <li>
                        <svg
                          className="svg-inline--fa fa-check-circle fa-w-16"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="check-circle"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          data-fa-i2svg=""
                        >
                          <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                          ></path>
                        </svg>
                        Database Development
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="content">
                  Our dedicated team of software developers is committed to
                  selecting the optimal tools and technologies to generate
                  substantial online traffic for your websites. As a reputable
                  software development company located in Durban, South Africa,
                  we possess extensive experience in creating resilient and
                  distinctive solutions that enhance your company's reputation.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-base client-border-radius p-xl-5 p-3 mt-5">
            <div className="tech-slideshow">
              <div className="mover-1">
                <img
                  src="/assets/img/technology_stacks/laravel_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/android_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/angular_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/apple_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/dotnet_icon.png"
                  alt="img"
                />

                <img
                  src="/assets/img/technology_stacks/firebase_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/flutter_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/html5_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/javascript_icon.png"
                  alt="img"
                />

                <img
                  src="/assets/img/technology_stacks/mongodb_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/mysql_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/nodejs_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/postgresql_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/python_icon.png"
                  alt="img"
                />

                <img
                  src="/assets/img/technology_stacks/react_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/redis_icon.png"
                  alt="img"
                />
                <img
                  src="/assets/img/technology_stacks/vue_icon.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
