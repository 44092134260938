const Header = () => {
  const imgStyle = {
    width: "35px", // Set the width to 200 pixels (you can use any valid CSS size)
    height: "35px",
    pending: "10px",
    margin: "10px",
  };
  return (
    <>
      {/* <!-- navbar start --> */}
      <nav className="navbar  fixed-top navbar-area navbar-expand-lg">
        <div className="container nav-container navbar-bg">
          <div className="responsive-mobile-menu">
            <button
              className="menu toggle-btn d-block d-lg-none"
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left"></span>
              <span className="icon-right"></span>
            </button>
          </div>
          <div className="logo">
            <a href="/">
              <h4
                className="title wow animated fadeInLeft"
                data-wow-duration="1.5s"
                data-wow-delay="0.4s"
              >
                <img src="/icon.png" style={imgStyle} alt="" />
                INFLUX <span style={{ color: "#fd2485" }}>IT</span>
              </h4>
            </a>
          </div>
          <div className="collapse navbar-collapse" id="itech_main_menu">
            <ul className="navbar-nav menu-open text-lg-end">
              <li>
                <a href="/#home_section">Home</a>
              </li>
              <li>
                <a href="/#about_section">About Us</a>
              </li>
              <li>
                <a href="/#service_section">Service</a>
              </li>
              <li>
                <a href="/#tech_section">Development</a>
              </li>
              <li>
                <a href="/#faqs_section">FAQs</a>
              </li>
              <li>
                <a href="/#contact_us_section">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop align-self-center">
            <a className="navbar-phone" href="tel:">
              <span className="icon">
                <img src="/assets/img/icon/1.png" alt="img" />
              </span>
              <span>Need help?</span>
              <h5>(+27) 68 310 7989</h5>
            </a>
          </div>
        </div>
      </nav>
      {/* <!-- navbar end --> */}
    </>
  );
};

export default Header;
