const currentYear = new Date().getFullYear();
const Footer = () => {
    const imgStyle = {
      width: "35px", // Set the width to 200 pixels (you can use any valid CSS size)
      height: "35px",
      pending: "10px",
      margin: "10px",
    };
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer className="footer-area bg-black bg-cover">
        <div className="footer-subscribe">
          <div className="container">
            <div
              className="footer-subscribe-inner bg-cover"
              style={{ backgroundImage: "url('/assets/img/bg/6.png')" }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="mb-lg-0 mb-3">Subscribe To Our Newsletter</h2>
                </div>
                <div className="col-lg-6 align-self-center text-lg-end">
                  <form id="newsletter_form">
                    <input
                      type="email"
                      id="newsletter_email"
                      name="newsletter_email"
                      placeholder="Your e-mail address"
                      required
                    />
                    <button
                      type="submit"
                      className="btn btn-black border-radius-0"
                    >
                      Submit now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <h4
                    className="title wow animated fadeInLeft"
                    data-wow-duration="1.5s"
                    data-wow-delay="0.4s"
                    style={{ color: "white" }}
                  >
                    <img src="/icon.png" style={imgStyle} alt="" />
                    INFLUX <span style={{ color: "#fd2485" }}>IT</span>
                  </h4>
                </div>
                <div className="details">
                  <p>
                    We believe that technology can make the world a better
                    place.
                  </p>
                  <p className="mt-3">
                    <i className="fa fa-phone-alt"></i> (+27) 68 310 7989
                  </p>
                  <p className="mt-2">
                    <i className="fas fa-envelope"></i> info@influxit.co.za
                  </p>
                  <ul className="social-media">
                    <li>
                      <a href="/#">
                        <i className="fab fa- facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <a href="#tech_section">
                      <i className="fas fa-arrow-right"></i> Mobile App
                      Development
                    </a>
                  </li>
                  <li>
                    <a href="#tech_section">
                      <i className="fas fa-arrow-right"></i> Web Development
                    </a>
                  </li>
                  <li>
                    <a href="#tech_section">
                      <i className="fas fa-arrow-right"></i> Backend Development{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#tech_section">
                      <i className="fas fa-arrow-right"></i> Database
                      Development
                    </a>
                  </li>
                  <li>
                    <a href="#tech_section">
                      <i className="fas fa-arrow-right"></i> UX and UI Design
                    </a>
                  </li>
                  <li>
                    <a href="#tech_section">
                      <i className="fas fa-arrow-right"></i> SaaS Product
                      Development
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Company</h4>
                <ul>
                  <li>
                    <a href="#home_section">
                      <i className="fas fa-arrow-right"></i> Home
                    </a>
                  </li>
                  <li>
                    <a href="#about_section">
                      <i className="fas fa-arrow-right"></i> About Us
                    </a>
                  </li>
                  <li>
                    <a href="#tech_section">
                      <i className="fas fa-arrow-right"></i> Our Technologies{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#process_section">
                      <i className="fas fa-arrow-right"></i> Our process
                    </a>
                  </li>
                  <li>
                    <a href="#service_section">
                      <i className="fas fa-arrow-right"></i> Our Services
                    </a>
                  </li>
                  <li>
                    <a href="#contact_us_section">
                      <i className="fas fa-arrow-right"></i> Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Inhouse Projects</h4>
                <ul>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="/assets/img/widget/1.png" alt="blog" />
                      </div>
                      <div className="media-body align-self-center">
                        <div className="post-info mb-2">
                          <i className="far fa-calendar-alt"></i>
                          <span>August 11, 2023</span>
                        </div>
                        <h6 className="title mb-0">
                          <a href="/#">PDF For ALL</a>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="/assets/img/widget/2.png" alt="blog" />
                      </div>
                      <div className="media-body align-self-center">
                        <div className="post-info mb-2">
                          <i className="far fa-calendar-alt"></i>
                          <span>August 21, 2023</span>
                        </div>
                        <h6 className="title mb-0">
                          <a href="/#">CV Maker</a>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© Influx IT {currentYear} | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <a href="/terms_and_conditions">Terms & Condition</a>
                <a href="/privacy_and_policy">Privacy Policy</a>
                <a href="#contact_us_section">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- footer area end --> */}

      {/* <!-- back to top area start --> */}
      <div className="back-to-top">
        <span className="back-top">
          <i className="fa fa-angle-up"></i>
        </span>
      </div>
      {/* <!-- back to top area end --> */}
    </>
  );
};

export default Footer;
