import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Faqs from "./Faqs";
import Home from "./Home";
import Services from "./Services";
import Header from "../common/Header";
import Footer from "../common/Footer";
import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";

const IndexHome = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="containerSpinnerStyle">
          <RingLoader
            color={"#F62490"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Header />
          <Home />
          <AboutUs />
          <Services />
          <Faqs />
          <ContactUs />
          <Footer />
        </>
      )}
    </>
  );
};

export default IndexHome;
