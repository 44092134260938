import Footer from "../common/Footer";
import Header from "../common/Header";
import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";

const PrivacyandPolicy = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="containerSpinnerStyle">
          <RingLoader
            color={"#F62490"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Header />
          <section className="service-area bg-relative pd-top-50 pd-bottom-55">
            <div className="container">
              <header>
                <h3>Influx IT - Privacy Policy</h3>
              </header>
              <main>
                <p>
                  At Influx IT, we are committed to respecting and protecting
                  your privacy. This Privacy Policy explains how we collect,
                  use, and safeguard your personal information.
                </p>

                <h5>Information We Collect</h5>
                <p>
                  We may collect personal information, including but not limited
                  to your name, contact information, and project details, when
                  you engage with our services or website.
                </p>

                <h5>How We Use Your Information</h5>
                <p>
                  We use your information to provide and improve our services,
                  communicate with you, and ensure the security of our systems.
                </p>

                <h5>Information Sharing</h5>
                <p>
                  We do not share your personal information with third parties
                  without your consent, except as required by law or to fulfill
                  our service obligations.
                </p>

                <h5>Security Measures</h5>
                <p>
                  We implement security measures to protect your information
                  from unauthorized access and use.
                </p>

                <h5>Your Choices</h5>
                <p>
                  You can update, correct, or delete your personal information
                  by contacting us.
                </p>

                <h5>Changes to this Policy</h5>
                <p>
                  We may update this Privacy Policy periodically. Any changes
                  will be posted on this page.
                </p>
              </main>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default PrivacyandPolicy;
