const Faqs = () => {
  const h5Style = {
    color: "#fc2385ff", // You can replace 'blue' with any color value you prefer
  };
  return (
    <>
      <section
        className="faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110"
        style={{
          backgroundImage: "url('assets/img/bg/3.png')",
        }}
        id="faqs_section"
      >
        <div className="container">
          <div className="row pd-top-120">
            <div className="col-xl-5 col-lg-6 col-md-8 order-lg-last">
              <div className="about-thumb-inner pt-lg-3">
                <img
                  className="main-img top_image_bounce"
                  src="/assets/img/about/4.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="section-title mb-0 mt-4 mt-lg-0">
                <h6 className="sub-title">SOME FAQ'S</h6>
                <h2 className="title">
                  FAQ about our <span>software development</span> services
                </h2>
                <p className="content">
                  Click on a questions to expand and reveal the answer!.
                </p>
              </div>
              <div
                className="accordion accordion-inner style-2 accordion-icon-left mt-3"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      How can I contact you to discuss my project?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      You could without difficulty touch us via our online
                      structure, skype, e-mail or cell smartphone. in case you
                      want to fulfill us immediately, we are happy to fulfill
                      you without delay and speak your challenge requirements.
                      Make sure to inform us as lots information about your
                      project as possible. It facilitates us to examine extra
                      approximately your task and proceed similarly seamlessly.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What happens after you complete the app?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We strive tough to construct the app which you sincerely
                      dreamed of. We no longer only deliver the product that is
                      completed but also provide app preservation and technical
                      aid to our clients. You may keep the app by means of your
                      self, as we provide all of the source code and technical
                      facts but even in any such case, experience free to
                      contact us if you require any help.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Do you offer UI/UX design services for my website?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      We provide exceptional UI/UX design services to clients
                      that easily attracts the attention of the end-users and
                      maximizes the traffic to your website, hence increasing
                      your sales and overall business revenue.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#headingFour"
                      aria-expanded="false"
                      aria-controls="headingFour"
                    >
                      Can you guide me to start an online website for my
                      business?
                    </button>
                  </h2>
                  <div
                    id="headingFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Our talented web development team, Project managers will
                      support you in every phase during the progress of the
                      project. Our website development process includes
                      research, wireframing, design, development, testing,
                      launch and free support and maintenance. With hands-on
                      experience in digital marketing services we can promote
                      your website so that your website will be easily reachable
                      to the end-users.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="work-process-area pd-top-10 pd-bottom-100"
        id="process_section"
      >
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">OUR SIMPLE PROCESS</h6>
            <h2 className="title">
              World <span>Best</span> Process
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="details">
                  <h5 className="mb-3" style={h5Style}>
                    Strategy
                  </h5>
                  <p className="content">
                    Our first step towards any project is Research. We try to
                    gather as much information about the business and customers
                    through qualitative and quantitive data. These data helps us
                    to find required information regarding target project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="details">
                  <h5 className="mb-3" style={h5Style}>
                    Design
                  </h5>
                  <p className="content">
                    After researching, we start to frame our research into an
                    efficient product. We start experiments to yield new and
                    unique design in order to eliminate preconceived theories
                    about particular product to look you best in the market.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="details">
                  <h5 className="mb-3" style={h5Style}>
                    Develop
                  </h5>
                  <p className="content">
                    Now we start implementing all our research and strategy to
                    build an architecture which easier to understand. We develop
                    step wise to cover all the vital components of project. And
                    finally with your reference we will put forward a beautiful
                    functional and final product.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="single-work-process-inner">
                <div className="details">
                  <h5 className="mb-3" style={h5Style}>
                    Support
                  </h5>
                  <p className="content">
                    This is where you go live, to the world. Design, marketing,
                    and maintenance; we'll be at your side for the life of your
                    site
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
