const AboutUs = () => {
  const animationStyle = {
    visibility: "visible",
    animationDelay: "0.4s",
    animationName: "fadeInUp",
  };
  return (
    <>
      <section className="about-area pd-top-120" id="about_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 wow animated fadeInLeft"
                data-wow-duration="1.5s"
                data-wow-delay="0.3s"
              >
                <img
                  className="main-img top_image_bounce"
                  src="/assets/img/about/4.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-wow-duration="1.5s"
              data-wow-delay="0.3s"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  We are <span>creative</span> problem solvers.
                </h2>
                <p className="content mb-4 mb-xl-5">
                  We are an exceptional group of innovators dedicated to
                  revolutionizing conventional business ideas into powerful
                  digital solutions. By employing cutting-edge strategies and
                  converting them into comprehensive approaches, we consistently
                  deliver outstanding results. Our established digital
                  methodologies align with industry benchmarks, offering
                  inclusive and easily accessible solutions to a global
                  audience.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="/assets/img/icon/2.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Design</h5>
                        <p>
                          We are creative problem solvers. We shape ideas into
                          functional and engaging products.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-about-inner">
                      <div className="thumb mb-3">
                        <img src="/assets/img/icon/3.png" alt="img" />
                      </div>
                      <div className="details">
                        <h5>Development</h5>
                        <p>
                          We engineer unique ideas into meaningful experiences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area pd-top-120" id="tech_section">
        <div className="container">
          <div className="section-title text-center">
            <h2>
              RESOURCE AS A <span>SERVICE</span>
            </h2>
            <p>
              Influx IT, a prominent digital transformation firm, has excelled
              in harnessing advanced technology stacks, enabling the delivery of
              distinctive and cutting-edge digital solutions.
            </p>
          </div>
          <div className="row member-row">
            <div className="row mx-md-n5">
              <div className="col px-md-5">
                <div
                  className="col s12 l6 wow fadeInUp animated"
                  data-wow-delay="0.3s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInUp",
                  }}
                >
                  <div className="member">
                    <div className="member-info mt-3">
                      <h3 className="member-infoht">Backend Development</h3>
                      <p>
                        Our proficient backend specialists craft customized
                        business solutions and boast extensive experience in
                        delivering feature-packed, improved user experiences.
                      </p>
                      <div className="row app_btmico">
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/nodejs_icon.png"
                                alt="Node.js"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>Node.js</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/laravel_icon.png"
                                alt="Laravel PHP"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>Laravel PHP</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/dotnet_icon.png"
                                alt=".Net"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>.Net</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/python_icon.png"
                                alt=".Python"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>Python</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/java_icon.png"
                                alt="Java Spring Boot"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>Java Spring Boot</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col px-md-5">
                <div
                  className="col s12 l6 wow fadeInUp animated"
                  data-wow-delay="0.2s"
                  style={animationStyle}
                >
                  <div className="member">
                    <div className="member-info">
                      <h3 className="member-infoht">Frontend Development</h3>
                      <p>
                        Our team of Front-end experts constructs secure and
                        intuitive applications, providing cutting-edge solutions
                        to elevate business opportunities.
                      </p>
                      <div className="row app_btmico">
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/angular_icon.png"
                                alt="Angular"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>Angular</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/react_icon.png"
                                alt="React"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>React</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/vue_icon.png"
                                alt="Vue.js"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>Vue.js</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <img
                              src="/assets/img/technology_stacks/html5_icon.png"
                              alt="HTML5"
                              className="img-fluid"
                              width="58"
                              height="58"
                            />
                            <p>HTML5</p>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <img
                              src="/assets/img/technology_stacks/javascript_icon.png"
                              alt="JavaScript"
                              className="img-fluid"
                              width="58"
                              height="58"
                            />
                            <p>JavaScript</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-md-n5">
              <div className="col px-md-5">
                <div
                  className="col s12 l6 wow fadeInUp animated"
                  data-wow-delay="0.2s"
                  style={animationStyle}
                >
                  <div className="member">
                    <div className="member-info">
                      <h3 className="member-infoht">Mobile App Development</h3>
                      <p>
                        Develop expandable and intuitive mobile applications for
                        iOS and Android platforms, catering to businesses, and
                        capitalize on the extensive expertise of our
                        professionals in the field of mobile app development.
                      </p>
                      <div className="row app_btmico">
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/apple_icon.png"
                                alt="iOS"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>iOS</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/android_icon.png"
                                alt="Android"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>Android</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/react_icon.png"
                                alt="React Native"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>React Native</p>
                            </a>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <a href="#service_section">
                              <img
                                src="/assets/img/technology_stacks/flutter_icon.png"
                                alt="Flutter"
                                className="img-fluid"
                                width="58"
                                height="58"
                              />
                              <p>Flutter</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="col s12 l6 wow fadeInUp animated"
                  data-wow-delay="0.4s"
                  style={animationStyle}
                >
                  <div className="member">
                    <div className="member-info">
                      <h3 className="member-infoht">Database Development</h3>
                      <p>
                        Our experts contribute to boosting superior business
                        results through top-notch database development,
                        leveraging the skills of our experienced database design
                        and development professionals.
                      </p>
                      <div className="row app_btmico">
                        <div className="col s3">
                          <div className="small_product_icon">
                            <img
                              src="/assets/img/technology_stacks/mongodb_icon.png"
                              alt="MongoDB"
                              className="img-fluid"
                              width="58"
                              height="58"
                            />
                            <p>MongoDB</p>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <img
                              src="/assets/img/technology_stacks/redis_icon.png"
                              alt="Redis"
                              className="img-fluid"
                              width="58"
                              height="58"
                            />
                            <p>Redis</p>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <img
                              src="/assets/img/technology_stacks/postgresql_icon.png"
                              alt="PostgreSQL"
                              className="img-fluid"
                              width="58"
                              height="58"
                            />
                            <p>PostgreSQL</p>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <img
                              src="/assets/img/technology_stacks/mysql_icon.png"
                              alt="MySQL"
                              className="img-fluid"
                              width="58"
                              height="58"
                            />
                            <p>MySQL</p>
                          </div>
                        </div>
                        <div className="col s3">
                          <div className="small_product_icon">
                            <img
                              src="/assets/img/technology_stacks/firebase_icon.png"
                              alt="Firebase"
                              className="img-fluid"
                              width="58"
                              height="58"
                            />
                            <p>Firebase</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
