import Footer from "../common/Footer";
import Header from "../common/Header";
import React, { useState, useEffect } from "react";
import RingLoader from "react-spinners/RingLoader";

const currentYear = new Date().getFullYear();

const Terms = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <>
      {loading ? (
        <div className="containerSpinnerStyle">
          <RingLoader
            color={"#F62490"}
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Header />
          <section className="service-area bg-relative pd-top-50 pd-bottom-55">
            <div className="container">
              <header>
                <h3>Influx IT - Terms and Conditions</h3>
              </header>
              <main>
                <h5>1. Acceptance of Terms</h5>
                <p>
                  By engaging in any business transactions, including but not
                  limited to requesting services, submitting orders, or making
                  payments with Influx IT, you acknowledge and agree to abide by
                  these Terms and Conditions outlined below.
                </p>

                <h5>2. Services Offered</h5>
                <p>
                  Influx IT offers custom software development services,
                  including software design, programming, testing, maintenance,
                  and related consulting services.
                </p>
                <h5>3. Project Initiation and Scope </h5>
                <p>
                  3.1. The client must provide a detailed project scope
                  outlining requirements, objectives, features, and any other
                  relevant information. <br />
                  3.2. Any changes to the project scope must be communicated in
                  writing and may result in adjustments to project timelines and
                  costs.
                </p>
                <h5>4. Payment Terms</h5>
                <p>
                  4.1. Influx IT will provide a cost estimate based on the
                  project scope, and payment terms will be specified in the
                  estimate. 4.2. A deposit may be required before work begins.
                  The balance is due upon completion of the project or as agreed
                  upon in the estimate.
                  <br />
                  4.3. Payments are accepted through the methods outlined in the
                  payment terms provided by Influx IT.
                </p>
                <h5>5. Project Timeline</h5>
                <p>
                  5.1. Influx IT will provide an estimated project timeline.
                  Delays may occur due to unforeseen circumstances, client
                  feedback, or changes to the project scope. <br />
                  5.2. Influx IT will make reasonable efforts to meet deadlines
                  but is not liable for any damages resulting from project
                  delays.
                </p>
                <h5>6. Intellectual Property</h5>
                <p>
                  6.1. The client retains ownership of any intellectual property
                  they provide to Influx IT. <br />
                  6.2. Any intellectual property created by Influx IT during the
                  project, unless otherwise agreed upon, will become the
                  property of the client upon full payment.
                </p>
                <h5>7. Confidentiality</h5>
                <p>
                  7.1. Both parties agree to keep any confidential information
                  exchanged during the project confidential and not to disclose
                  it to third parties. <br />
                  7.2. Influx IT may share confidential information with its
                  employees or contractors as necessary for project completion.
                </p>
                <h5>8. Warranty and Support</h5>
                <p>
                  8.1. Influx IT will provide a warranty period during which any
                  defects or errors discovered in the delivered software will be
                  corrected at no additional cost. <br />
                  8.2. Ongoing support and maintenance can be provided after the
                  warranty period under terms and costs to be agreed upon
                  separately.
                </p>
                <h5>9. Liability and Indemnification</h5>
                <p>
                  9.1. Influx IT is not liable for any indirect, consequential,
                  or incidental damages arising from the use of the software.
                  9.2. The client agrees to indemnify and hold harmless Influx
                  IT from any claims, damages, losses, or liabilities arising
                  from the client's use of the software.
                </p>
                <h5>10. Termination</h5>
                <p>
                  Either party may terminate the project or engagement with
                  written notice if the other party breaches these terms or if
                  circumstances make continuation impractical.
                </p>

                <h5>11. Governing Law and Jurisdiction</h5>
                <p>
                  These Terms and Conditions are governed by the laws of [Your
                  Jurisdiction]. Any disputes will be subject to the exclusive
                  jurisdiction of the courts in [Your Jurisdiction].
                </p>

                <h5>By proceeding, you acknowledge</h5>
                <p>
                  By proceeding with the engagement, you acknowledge that you
                  have read, understood, and agreed to these Terms and
                  Conditions.
                </p>
                <h5>Influx IT</h5>
                <p>{currentYear}</p>
              </main>
            </div>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default Terms;
