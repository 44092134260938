import EmailJS from "emailjs-com";
import Swal from "sweetalert2";
import React, { useState } from "react";

const ContactUs = () => {
  const [buttonText, setButtonText] = useState("Submit Now");
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    EmailJS.sendForm(
      "service_bqhwdvs",
      "template_ke9p80k",
      e.target,
      "mSuhSpow7VZ2y1LNN"
    ).then(
      (response) => {
        console.log("Email sent successfully!", response.status, response.text);
        Swal.fire({
          title: "Mail Sent!",
          text: "Email sent successfully!",
          icon: "success",
          confirmButtonText: "Cool",
        });

        e.target.reset();
        setButtonText("Submit Now");
        setButtonDisabled(false);
      },
      (error) => {
        //console.log(error);
        // Swal.fire({
        //   title: "Mail Sent!",
        //   text: "Email sent successfully!",
        //   icon: "success",
        //   confirmButtonText: "Cool",
        // });
        console.log(error.text);
        Swal.fire({
          title: "Mail Failed!",
          text: error.text,
          icon: "error",
          confirmButtonText: "Cool",
        });
        setButtonText("Submit Now");
        setButtonDisabled(false);
      }
    );
  };
  return (
    <>
      <section
        className="contact-area pd-top-120 pd-bottom-90"
        id="contact_us_section"
      >
        <div className="container">
          <div className="contact-inner-1">
            <img
              className="top_image_bounce animate-img-1"
              src="/assets/img/banner/2.png"
              alt="img"
            />
            <img
              className="top_image_bounce animate-img-2"
              src="/assets/img/about/6.png"
              alt="img"
            />
            <div className="row">
              <div
                className="col-lg-8 wow animated fadeInLeft"
                data-wow-duration="1.5s"
                data-wow-delay="0.3s"
              >
                <img
                  className="w-100"
                  style={{ borderRadius: "1%" }}
                  src="/assets/img/bg/4.png"
                  alt="img"
                />
              </div>
              <div
                className="col-lg-4 wow animated fadeInRight"
                data-wow-duration="1.5s"
                data-wow-delay="0.3s"
              >
                <div className="section-title mb-0">
                  <h6 className="sub-title">GET IN TOUCH</h6>
                  <h2 className="title">
                    Bringing Your <span>Vision</span> To Life
                  </h2>
                  <p className="content">
                    We believe that something truly amazing can come from
                    combining your vision with our experience.
                  </p>
                  <form
                    className="mt-4"
                    onSubmit={sendEmail}
                    id="contact_us_form"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            name="from_name"
                            id="from_name"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Your Email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            placeholder="Your Phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Your Subject"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea
                            placeholder="Message"
                            name="message"
                            id="message"
                            required
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn btn-black mt-0 w-100 border-radius-5"
                          disabled={buttonDisabled}
                          onClick={() => {
                            setButtonText("Sending.....");
                          }}
                        >
                          {!buttonDisabled ? (
                            buttonText
                          ) : (
                            <img
                              src="/assets/img/svg/loader.svg"
                              alt="svg"
                              className="spinners"
                            />
                          )}
                          
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
